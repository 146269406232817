import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';

import './timeout.scss';

const TimeoutPage = injectIntl(({ intl }) => (
  <Layout pageName="online-banking-timeout-page">
    <div id="timeout-page-wrapper" className="page-content-wrapper">
      <section className="timeout-page-content">
        <h1 id="timeout-title">
          <FormattedMessage id="pages.online-banking-timeout.timeout-title" />
        </h1>
        <p id="timeout-body-text">
          <FormattedMessage id="pages.online-banking-timeout.timeout-body-text" />
        </p>
        <Link
          to={intl.formatMessage({ id: 'pages.online-banking-timeout.verified-link' })}
          className="timeout-log-in-button-link progressive button timeout-log-in"
          target="_self"
        >
          <FormattedMessage id="pages.online-banking-timeout.button-text" />
        </Link>
      </section>
    </div>
  </Layout>
));

export default TimeoutPage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.online-banking-timeout.page-title" intl={pageContext.intl}>
    <meta name="robots" content="noindex" />
  </SEO>
);
/* eslint-enable react/prop-types */
